// extracted by mini-css-extract-plugin
export var btn = "WhyChoose-module--btn--a78d2";
export var cards = "WhyChoose-module--cards--df912";
export var description = "WhyChoose-module--description--755be";
export var description2 = "WhyChoose-module--description2--e02c7";
export var hire = "WhyChoose-module--hire--1cc09";
export var hireImg = "WhyChoose-module--hireImg--d9d2e";
export var subTitle = "WhyChoose-module--subTitle--b4511";
export var talent = "WhyChoose-module--talent--49dda";
export var teamBtn = "WhyChoose-module--teamBtn--d2f53";
export var text = "WhyChoose-module--text--fb7f2";
export var tick = "WhyChoose-module--tick--fffa0";
export var whySQAservices = "WhyChoose-module--whySQAservices--fa33b";