// extracted by mini-css-extract-plugin
export var ReadyOpportunity = "Opportunity-module--ReadyOpportunity--65595";
export var bannerBtn = "Opportunity-module--bannerBtn--94abf";
export var btn_white2_border_Golang = "Opportunity-module--btn_white2_border_Golang--5a74c";
export var card = "Opportunity-module--card--ae1e7";
export var cardWrapper = "Opportunity-module--cardWrapper--0df05";
export var cmsHeading = "Opportunity-module--cmsHeading--3c4cb";
export var cmsOpportunityBanner = "Opportunity-module--cmsOpportunityBanner--67d72";
export var cmsWebDescription = "Opportunity-module--cmsWebDescription--9adb4";
export var con = "Opportunity-module--con--8f673";
export var description = "Opportunity-module--description--fb012";
export var description2 = "Opportunity-module--description2--8adc5";
export var heading = "Opportunity-module--heading--c107c";
export var hireCtoOpportunityBanner = "Opportunity-module--hireCtoOpportunityBanner--7b15f";
export var noCodeHeading = "Opportunity-module--noCodeHeading--730b1";
export var nocodeopportunity = "Opportunity-module--nocodeopportunity--0b6b3";
export var productDescription = "Opportunity-module--productDescription--8e941";
export var productHeading = "Opportunity-module--productHeading--14a26";
export var productOpportunityBanner = "Opportunity-module--productOpportunityBanner--6ae8f";
export var readyWebOpportunity = "Opportunity-module--readyWebOpportunity--c8036";
export var rescueHeading = "Opportunity-module--rescueHeading--b56a7";
export var subserviceOpportunityWeb = "Opportunity-module--subserviceOpportunityWeb--006de";
export var vsJsBannerBg = "Opportunity-module--vsJsBannerBg--bc049";
export var vsJsBannerDec = "Opportunity-module--vsJsBannerDec--904d5";
export var webAppOpportunity = "Opportunity-module--webAppOpportunity--3f67f";