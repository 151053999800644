import React, { useState, useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import AccordionContext from "react-bootstrap/AccordionContext"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./CmsProcess.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./CmsProcess.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  const isFirstStep = eventKey === "ek-0"

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isFirstStep && isCurrentEventKey
              ? styles.quesColorSelected
              : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button" className={styles.faqButton}>
          {isFirstStep && isCurrentEventKey ? (
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/minus_a92cc17cc4.svg"
              style={{ height: "30px", width: "30px" }}
              className={styles.quesColorSelected}
              decoding="async"
              loading="lazy"
              alt="minus"
            />
          ) : (
            <img
              src={
                isCurrentEventKey
                  ? "https://invozone-backend.s3.us-east-1.amazonaws.com/minus_29fba22bdb.svg"
                  : "https://invozone-backend.s3.us-east-1.amazonaws.com/plus_5d5e953ba8.svg"
              }
              style={{ height: "30px", width: "30px" }}
              className={styles.quesColor}
              decoding="async"
              loading="lazy"
              alt="plus"
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const CmsProcess = ({ strapiData, webProcess, hireCtoProcess }) => {
  const [imageInd, setImageInd] = useState(0)
  const [current, setCurrent] = useState(0)
  const handleStepClick = index => {
    if (index === imageInd) {
      setImageInd(0)
      setCurrent(0)
    } else {
      setImageInd(index)
      setCurrent(index)
    }
  }
  const image =
    strapiData?.cards[imageInd]?.image1 &&
    getImage(strapiData?.cards[imageInd]?.image1[0]?.localFile)

  return (
    <div
      className={`${styles.CmsProcessWeb} ${
        webProcess ? styles.processQa : ""
      } ${hireCtoProcess ? styles.hireCtoProcess : ""}`}
    >
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className="align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
            className={styles.colCmsWebLeft}
          >
            <div className=" cmsProcessSteps faqsAccordian d-flex">
              <Accordion defaultActiveKey={`ek-${imageInd}`}>
                {strapiData?.cards?.map((el, i) => (
                  <Card
                    key={i}
                    className={`${"custom-border"}`}
                    style={{ overflow: "visible" }}
                    onClick={() => handleStepClick(i)}
                  >
                    <ContextAwareToggle
                      eventKey={`ek-${i}`}
                      ind={`ek-${imageInd}`}
                      isFirstStep={i === 0}
                    >
                      <div className={` ${styles.faqsNum}`}>
                        {hireCtoProcess ? (
                          <img
                            src="https://invozone-backend.s3.us-east-1.amazonaws.com/hire_Cto_Check_4d62eb15a8.svg"
                            alt=""
                            style={{
                              height: "24px",
                              width: "24px",
                              marginRight: "15px",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <h3
                          className={`${styles.faqsSub} py-2 mb-0`}
                          type="button"
                        >
                          {el?.title}
                        </h3>
                        {hireCtoProcess ? (
                          ""
                        ) : (
                          <>
                            <span className={styles.serial2}>
                              Step 0{+(i + 1)}
                            </span>
                            <span className={styles.serial}>
                              Step 0{+(i + 1)}
                            </span>
                          </>
                        )}
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse
                      className={`${
                        imageInd === i && "show custom-transition"
                      }`}
                    >
                      <Card.Body className={styles.textColor}>
                        <div
                          className={styles.accordDes}
                          dangerouslySetInnerHTML={{
                            __html: el?.subTitle,
                          }}
                        />
                        <p
                          className="pt-5"
                          dangerouslySetInnerHTML={{
                            __html: el?.description?.description,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.crossImg}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                  class={styles.hireImg}
                />
              ) : (
                <img
                  src={
                    strapiData?.cards[imageInd]?.image1 &&
                    strapiData?.cards[imageInd]?.image1[0]?.localFile?.publicURL
                  }
                  alt={strapiData?.title}
                  decoding="async"
                  loading="lazy"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CmsProcess
