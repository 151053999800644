// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--14f5c";
export var clientDots2 = "Testimonials-module--clientDots2--001da";
export var content = "Testimonials-module--content--ebe23";
export var dots3 = "Testimonials-module--dots3--e7b9c";
export var hireArrow = "Testimonials-module--hireArrow--4871c";
export var iconContainer = "Testimonials-module--iconContainer--90d35";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--53376";
export var imgBtn = "Testimonials-module--imgBtn--61cef";
export var person = "Testimonials-module--person--31fa9";
export var personBg = "Testimonials-module--personBg--10ae1";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--5988e";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--3ae45";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--85676";
export var row = "Testimonials-module--row--0827f";
export var section = "Testimonials-module--section--5233f";
export var staffRec = "Testimonials-module--staffRec--ec5f9";
export var testName = "Testimonials-module--testName--7a077";
export var testimonials = "Testimonials-module--testimonials--5c689";
export var testimonialsContent = "Testimonials-module--testimonialsContent--075c2";
export var vector2 = "Testimonials-module--vector2--49900";