// extracted by mini-css-extract-plugin
export var cardBg = "PowerPackedMobile-module--cardBg--cb0fa";
export var cards = "PowerPackedMobile-module--cards--cf3ad";
export var cardsContent = "PowerPackedMobile-module--cardsContent--8fc9f";
export var description = "PowerPackedMobile-module--description--dc72d";
export var heading = "PowerPackedMobile-module--heading--151f9";
export var iconssCard = "PowerPackedMobile-module--iconssCard--4d975";
export var nav = "PowerPackedMobile-module--nav--65366";
export var navItem = "PowerPackedMobile-module--nav-item--3b38e";
export var navbarBlock = "PowerPackedMobile-module--navbarBlock--90656";
export var poweredSQAMobileTech = "PowerPackedMobile-module--poweredSQAMobileTech--2354a";
export var tabData = "PowerPackedMobile-module--tabData--23bbf";
export var tech = "PowerPackedMobile-module--tech--b53b9";
export var techIcon = "PowerPackedMobile-module--techIcon--f1a6e";
export var techImg = "PowerPackedMobile-module--techImg--b8afa";
export var technologyIcon = "PowerPackedMobile-module--technologyIcon--e58c8";