// extracted by mini-css-extract-plugin
export var accImg = "Engineering-module--accImg--e4959";
export var approachImg = "Engineering-module--approachImg--6882b";
export var btn_white1_border = "Engineering-module--btn_white1_border--e2be6";
export var description = "Engineering-module--description--75ea0";
export var engineeringSQA = "Engineering-module--engineeringSQA--c29e1";
export var head = "Engineering-module--head--48068";
export var heading = "Engineering-module--heading--5b9f4";
export var keyBtn = "Engineering-module--keyBtn--9ebb5";
export var nextGenRight = "Engineering-module--nextGenRight--892ea";
export var quesColor = "Engineering-module--quesColor--aa7d5";
export var quesColorSelected = "Engineering-module--quesColorSelected--c6af0";
export var someColor = "Engineering-module--someColor--00637";
export var textColor = "Engineering-module--textColor--7e1bf";