// extracted by mini-css-extract-plugin
export var cardBg = "PowerPacked-module--cardBg--95cde";
export var cards = "PowerPacked-module--cards--ab280";
export var cardsContent = "PowerPacked-module--cardsContent--1da1a";
export var description = "PowerPacked-module--description--6b811";
export var heading = "PowerPacked-module--heading--314f6";
export var iconssCard = "PowerPacked-module--iconssCard--689d8";
export var nav = "PowerPacked-module--nav--a9b65";
export var navItem = "PowerPacked-module--nav-item--4d515";
export var navbarBlock = "PowerPacked-module--navbarBlock--2c4a6";
export var powerPackedSQATechStacks = "PowerPacked-module--powerPackedSQATechStacks--4799f";
export var tabData = "PowerPacked-module--tabData--566b4";
export var tech = "PowerPacked-module--tech--51c8c";
export var techIcon = "PowerPacked-module--techIcon--6b07b";
export var techImg = "PowerPacked-module--techImg--cb142";
export var technologyIcon = "PowerPacked-module--technologyIcon--0f422";