import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Partner.module.scss"

const Partner = ({ strapiData }) => {
  return (
    <div className={styles.partnerSqa}>
      <Container>
        <div className={styles.heading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className="gap-30">
          {strapiData?.cards?.map((e, i) => (
            <Col
              lg={3}
              md={6}
              sm={12}
              xs={12}
              className=" justify-content-center  d-flex"
              key={i}
            >
              <div className={styles.cards}>
                <lottie-player
                  autoplay
                  loop
                  src={e?.image1[0]?.localFile?.publicURL}
                  style={{ height: "60px", width: "60px", margin: "auto" }}
                />
                <h3>{e?.title}</h3>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Partner
