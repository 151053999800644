// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--60a9b";
export var circleTes = "Testimonials2-module--circleTes--0bffd";
export var content = "Testimonials2-module--content--bdde5";
export var des = "Testimonials2-module--des--bd67a";
export var iconContainer = "Testimonials2-module--iconContainer--34777";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--39ab7";
export var personBg = "Testimonials2-module--personBg--68a7d";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--0e5ae";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--05f42";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--a9776";
export var row = "Testimonials2-module--row--54f30";
export var section = "Testimonials2-module--section--d2d6b";
export var tesBtn = "Testimonials2-module--tesBtn--d455d";
export var tesDots = "Testimonials2-module--tesDots--a84b4";
export var tesRec = "Testimonials2-module--tesRec--7a0b6";
export var testimonials = "Testimonials2-module--testimonials--39670";