// extracted by mini-css-extract-plugin
export var bottomExperience = "Kill-module--bottomExperience--c49bd";
export var btn_white2_border_banner_web_App_need_btn = "Kill-module--btn_white2_border_banner_web_App_need_btn--9178b";
export var experienceData = "Kill-module--experienceData--0a616";
export var experienceDetails = "Kill-module--experienceDetails--4f94a";
export var experienceTitle = "Kill-module--experienceTitle--0398d";
export var headingWeb = "Kill-module--headingWeb--eb885";
export var killContainerSqa = "Kill-module--killContainerSqa--a2b5d";
export var killmain = "Kill-module--killmain--e2121";
export var needBtnWeb = "Kill-module--needBtnWeb--36f35";
export var needContent = "Kill-module--needContent--07028";