// extracted by mini-css-extract-plugin
export var CmsProcessWeb = "CmsProcess-module--CmsProcessWeb--4a72a";
export var accordDes = "CmsProcess-module--accordDes--2f9d9";
export var colCmsWebLeft = "CmsProcess-module--colCmsWebLeft--9730d";
export var crossImg = "CmsProcess-module--crossImg--781a5";
export var description = "CmsProcess-module--description--564ba";
export var description2 = "CmsProcess-module--description2--93bc9";
export var faqButton = "CmsProcess-module--faqButton--5db3f";
export var faqsNum = "CmsProcess-module--faqsNum--f1e84";
export var faqsSub = "CmsProcess-module--faqsSub--34274";
export var heading = "CmsProcess-module--heading--47dd6";
export var headr = "CmsProcess-module--headr--f6dff";
export var hireCtoProcess = "CmsProcess-module--hireCtoProcess--f8f6e";
export var processQa = "CmsProcess-module--processQa--0ddee";
export var quesColor = "CmsProcess-module--quesColor--4af60";
export var quesColorSelected = "CmsProcess-module--quesColorSelected--797bd";
export var reverse = "CmsProcess-module--reverse--4055a";
export var serial = "CmsProcess-module--serial--36e8a";
export var serial2 = "CmsProcess-module--serial2--906b7";
export var textColor = "CmsProcess-module--textColor--97f3e";