// extracted by mini-css-extract-plugin
export var banerImg = "Banner-module--banerImg--6d569";
export var bannerBtn = "Banner-module--bannerBtn--3d8b2";
export var bannerHeading = "Banner-module--bannerHeading--b188c";
export var bannerRight = "Banner-module--bannerRight--4c236";
export var bannerSQAassurance = "Banner-module--bannerSQAassurance--a295a";
export var btn_white2_border = "Banner-module--btn_white2_border--710db";
export var cards = "Banner-module--cards--027ba";
export var head = "Banner-module--head--9e4d4";
export var heading = "Banner-module--heading--6168d";
export var subTitle = "Banner-module--subTitle--8a95a";
export var subTitle2 = "Banner-module--subTitle2--159ba";
export var talent = "Banner-module--talent--362d2";
export var text = "Banner-module--text--9c115";
export var tick = "Banner-module--tick--3a5e1";