import { Link } from "gatsby"
import React from "react"
import * as styles from "./Kill.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Kill = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.killmain}>
        <Container className={styles.killContainerSqa}>
          <Row className="align-items-center">
            <Col lg={8} md={12} xs={12}>
              <div className={styles.needContent}>
                <h2
                  className={styles.headingWeb}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
                <div className={`${styles.needBtnWeb}`}>
                  {strapiData?.buttons[0] && (
                    <Link
                      to={strapiData?.buttons[0]?.url}
                      className={
                        styles.btn_white2_border_banner_web_App_need_btn
                      }
                    >
                      {strapiData?.buttons[0]?.title}
                    </Link>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={4} xs={12} md={12}>
              <div
                className={` justify-content-center d-flex ${styles.bottomExperience}`}
              >
                {strapiData?.cards?.map((e, i) => (
                  <div className={styles.experienceDetails} key={i}>
                    <p
                      className={styles.experienceData}
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                    <h3 className={styles.experienceTitle}>{e?.title}</h3>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Kill
