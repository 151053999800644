import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import * as styles from "./Opportunity.module.scss"

const Opportunity = ({
  strapiData,
  cmsopportunity,
  webOpportunity,
  rescueOpportunity,
  productOpportunity,
  vsJsBanner,
  hireCtoOpportunity,
}) => {
  return (
    <div
      className={`${styles.subserviceOpportunityWeb} ${
        cmsopportunity ? styles.cmsOpportunityBanner : ""
      } ${webOpportunity ? styles.webAppOpportunity : ""} ${
        productOpportunity ? styles.productOpportunityBanner : ""
      } ${vsJsBanner ? styles.vsJsBannerBg : ""}
      } ${hireCtoOpportunity ? styles.hireCtoOpportunityBanner : ""}`}
    >
      <Container className={styles.con}>
        <div
          className={`${styles.ReadyOpportunity} ${
            webOpportunity ? styles.readyWebOpportunity : ""
          }`}
        >
          <h2
            className={`${styles.heading} ${
              cmsopportunity ? styles.cmsHeading : ""
            } ${rescueOpportunity ? styles.rescueHeading : ""} ${
              hireCtoOpportunity ? styles.hireCtoHeading : ""
            } `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={`${styles.description} ${
              cmsopportunity ? styles.cmsWebDescription : ""
            } ${productOpportunity ? styles.productDescription : ""} ${
              vsJsBanner ? styles.vsJsBannerDec : ""
            }
            } ${productOpportunity ? styles.productDescription : ""}
            ${hireCtoOpportunity ? styles.hireCtoDescription : ""}`}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_white2_border_Golang}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Opportunity
